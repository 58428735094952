import { ReactNode } from 'react';
import {useMediaQuery} from "react-responsive";
import backgroundMobile from '../images/background_mobile_blue.svg';

type IBackgroundProps = {
  children: ReactNode;
};

const Background = (props: IBackgroundProps) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    })

    return (<>
            <div className="text-primary-100 w-screen h-screen">

                { isDesktopOrLaptop
                    ? <img className="absolute w-screen h-screen" style={{left: -220, zIndex: -5000}}
                           src='/assets/images/background_desktop_blue.svg' alt=""/>
                    : <img
                        className="absolute w-screen overflow-x-hidden darkBg"
                        style={{ zIndex: -5000 }}
                        src={backgroundMobile}
                     alt=""/> }

                {props.children}
            </div>
        </>
    );
}

export { Background };
