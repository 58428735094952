import {scanFile} from "../pdfscan";
import {compareCredentialWithOriginal} from "./OriginalCredential";
import { getSha256DocumentHash } from "./getDocumentHash";

export async function retrieve(dropFile: any): Promise<any> {
    const retrieved = {qr: '', certId: '', qrFileHash: '', equalHash: false};
    let resultBody = {status: 'ok', message: 'File uploaded successfully', retrieved};
    try {
        const retrievedQR: string | null = await scanFile(dropFile);
        const blob = new Response(dropFile);
        const blobBff = await blob.blob();
        let file = await blobBff.arrayBuffer();
        const qrFileHash = await getSha256DocumentHash(file);
        const credentialId = retrievedQR!.substring(retrievedQR!.lastIndexOf('/') + 1);
        resultBody = {
            ...resultBody,
            retrieved: {qr: retrievedQR!, certId: credentialId, qrFileHash: qrFileHash, equalHash: true}
        }
    } catch (e: any) {
        let msg = ''
        if (e?.name === "InvalidPDFException") msg = "Invalid PDF";
        else if (e instanceof Event) msg = "Invalid Image";
        else msg = "we can't access the file";
        resultBody = {
            status: 'fail', message: msg, retrieved: retrieved
        }
    }

    try {
        const hashEquals = await compareCredentialWithOriginal(resultBody.retrieved.certId, resultBody.retrieved.qrFileHash);
        resultBody = {...resultBody, retrieved: {...resultBody.retrieved, equalHash: hashEquals}}
    } catch (e: any) {
        resultBody = {
            status: 'fail',
            message: 'Error retrieving original file hash',
            retrieved: { ...resultBody.retrieved, equalHash: false }
        }
    }

    return resultBody;
}
