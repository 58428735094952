import { Banner } from './Banner';
import { Footer } from './footer/Footer';
import {PdfUpload} from "./PdfUpload";
import {HowItWorks} from "./HowItWorks";
import {useEffect, useState} from "react";
import {StepperStatus} from "../verifier/StepperStatus";
import Navocerts from "../components/blockcerts/Navocerts";
import cert from "../components/blockcerts/testnet-valid-2.0.json";
import {VerifierMain} from "../verifier/main";
import {Button} from "../button/Button";
import buttonIcon from "../images/document.svg";
import {useMediaQuery} from "react-responsive";
import {ICTABannerProps} from "./Banner";
const defaultImage = "/assets/images/square_logo_navozyme.png";

const Base = () => {

    const [parsedCert, setParsedCert] = useState<any>();
    const [documentProcessed, setDocumentProcessed] = useState<boolean>(false);
    const [hashNotEqual, setHashNotEqual] = useState<boolean>(true);
    // for the new verification method with raw.icp0.io
    const [skipHashCheck, setSkipHashCheck] = useState<boolean>(false);

    const getDefaultBannerProps = (): ICTABannerProps  => {
        return {
            title: "PDF verification",
            subtitle: "Upload a PDF file to verify it.",
            button: false
        }
    }

    const [bannerProps, setBannerProps] = useState<ICTABannerProps>(getDefaultBannerProps())

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    })

    useEffect(() => {
        if (parsedCert && (skipHashCheck ? true : !hashNotEqual)) {
            setBannerProps({
                title: parsedCert.badge?.name,
                subtitle: parsedCert.badge?.description,
                button: getHeaderButton()
            });
        }
    }, [parsedCert, hashNotEqual, skipHashCheck])

    const _onResponse = async (resp: any) => {
        setDocumentProcessed(true);
        setHashNotEqual(!resp.retrieved.equalHash);
        const navocerts = new Navocerts(cert, resp.retrieved.certId, resp.retrieved.qrFileHash);
        navocerts.call().then((parsed) => {
            setSkipHashCheck(parsed.STATUS === "SKIP_REVOCATION_LIST_CHECK");
            setParsedCert(parsed);
        });
    }

    const _onReset = () => {
        setHashNotEqual(false);
        setParsedCert(false);
        setDocumentProcessed(false);
        setBannerProps(getDefaultBannerProps);
    }

    const getHeaderButton = () => {
        return <Button children={'Upload new file'} icon={buttonIcon} onClick={_onReset} hideMobile={!isDesktopOrLaptop}/>
    }

    return (
        <>
            <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-hidden">
                <div className="flex-1">
                    <Banner title={bannerProps.title} subtitle={bannerProps.subtitle} button={bannerProps.button}/>
                </div>

                { documentProcessed && parsedCert && (skipHashCheck ? true : !hashNotEqual) && (
                    <div className="flex-1 pb-10">
                        <VerifierMain src={parsedCert} image={defaultImage} hideSteps={true}/>
                    </div>
                )}

                { (!documentProcessed || !parsedCert || (skipHashCheck ? false : hashNotEqual)) && (
                    <div className="flex-1 pb-10">
                        <PdfUpload _onResponse={_onResponse} _onReset={_onReset} />
                    </div>
                )}

                { parsedCert &&
                <>
                    <div className="flex-1 px-10">
                        <StepperStatus 
                            src={parsedCert} 
                            exceptionHashNotUnique={(skipHashCheck ? false : hashNotEqual)} 
                        />
                    </div>
                </>
                }

                <div className="flex flex-col grow items-center justify-end pb-8 sm:pb-36">
                    <HowItWorks />
                    <Footer />
                </div>
            </div>
        </>
    );
}

export { Base };
