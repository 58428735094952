import {ButtonStatus} from "../button/ButtonStatus";


type IStatusProps = {
    verificationStatus?: string;
};

const VerificationStatus = (props: IStatusProps) => {

    const getStatusLabel = (status: string) => {
        switch (status) {
            case 'success':
                return <ButtonStatus bold xl type="success">Verified</ButtonStatus>
            case 'failure':
                return <ButtonStatus bold xl type="invalid">Invalid</ButtonStatus>;
            case 'revoked':
                return <ButtonStatus bold xl type="invalid">Revoked</ButtonStatus>;
            case 'error':
                return <ButtonStatus bold xl type="invalid">Unable to Verify. Network Error</ButtonStatus>
            default:
                return <></>
        }
    }

    return (
        <section>
            {
                getStatusLabel(props.verificationStatus ?? "")
            }
        </section>

    );
}

export { VerificationStatus };
