import React from "react";
import {Section} from "../layout/Section";

const HowItWorks = () => {
    const [showModal, setShowModal] = React.useState(false);

    return (
        <Section>
            <div className="text-center">
                <button
                    className="
                bg-gray-100 w-80 h-9 font-medium text-primary-900 text-sm px-6 rounded-lg shadow-black hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                <span className="flex flex-row justify-center items-center">
                    <span className="pr-1">
                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.53846C7.32682 3.53846 3.53846 7.32682 3.53846 12C3.53846 16.6732 7.32682 20.4615 12 20.4615C16.6732 20.4615 20.4615 16.6732 20.4615 12C20.4615 7.32682 16.6732 3.53846 12 3.53846ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="#030D45"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 16.359C12.4248 16.359 12.7692 16.0146 12.7692 15.5897V11.4872C12.7692 11.0623 12.4248 10.7179 12 10.7179C11.5752 10.7179 11.2308 11.0623 11.2308 11.4872V15.5897C11.2308 16.0146 11.5752 16.359 12 16.359Z" fill="#030D45"/>
<path d="M13.0256 8.41026C13.0256 7.84381 12.5664 7.38462 12 7.38462C11.4336 7.38462 10.9744 7.84381 10.9744 8.41026C10.9744 8.9767 11.4336 9.4359 12 9.4359C12.5664 9.4359 13.0256 8.9767 13.0256 8.41026Z" fill="#030D45"/>
</svg>
                    </span>
                    <span>
                        How it works
                    </span>
                </span>
                </button>
            </div>



            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 mx-2 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-5xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <h3 className="p-1 text-2xl text-primary-900 font-semibold">
                                        How does it work?
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-primary-800 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        ×
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 pt-0 flex-auto">
                                    <p className="my-4 text-primary-900 text-base sm:text-lg leading-relaxed">
                                        The certificate data is checked in real-time directly from the issuer in an automated secure manner.
                                    </p>
                                    <p className="my-4 text-primary-900 text-base sm:text-lg leading-relaxed">
                                        The slightest change in the certificate’s data will trigger an invalid result. So when you see the green check mark, you can be rest assured that the certificate is authentic and valid.
                                    </p>
                                    <p className="my-4 text-primary-900 text-base sm:text-lg leading-relaxed">
                                        N-CAP, the Navozyme-Certificate Authentication Platform, uses cutting edge technology to ensure the highest standards of  data security and privacy.
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 rounded-b">

                                    <button
                                        className="bg-primary-900 text-white w-full active:bg-primary-800 font-bold text-lg sm:text-xl px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Understood
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"/>
                </>
            ) : null}
        </Section>
    );
}

export {HowItWorks};
