/* eslint-disable */
import Settings from './settings';
import axios, {Axios, AxiosError, AxiosResponse, HttpStatusCode} from 'axios';
import {newAbortSignal} from "../utils/abortSignal";

class ApiCaller {
    token: string;

    constructor(token?: string) {
        this.token = token ? token : "";
    }

    _generateRequestData(url: string, method: string, params: any, token?: string): { url: string, fetchParams: any, validateStatus?: any} {
        const fetchParams: any = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        if (params) {
            fetchParams.body = JSON.stringify(params);
        }

        if (token) {
            fetchParams.headers['x-access-token'] = token;
        }

        let separator = '?';
        if(url.indexOf('?') !== -1) {
            separator = '&';
        }

        const defUrl = Settings.getApiURL() + url + (this.token ? separator + "token=" + this.token : "");

        return { url: defUrl, fetchParams: fetchParams }
    }

    call(url:string, method:string, params?:object, token?:string) : Promise<any> {
        const requestData = this._generateRequestData(url, method, params, token);

        return axios.get(requestData.url, {
            signal: newAbortSignal(10000)
        }).then((response: AxiosResponse<any>) => {
            return response.data;
        }).catch((error: AxiosError<any>) => {
            throw new Error("NETWORK_ERROR");
        });
    }

    callGetExternal(url:string) : Promise<any> {
        return axios.get(url, {
            signal: newAbortSignal(10000)
        }).then((response: AxiosResponse<any>) => {
            return response.data;
        }).catch((error: AxiosError<any>) => {
            throw new Error("NETWORK_ERROR");
        });
    }

    download(url: string, method: string, params?: object): Promise<any> {
        const requestData = this._generateRequestData(url, method, params)

        return fetch(requestData.url, requestData.fetchParams).then(
            (response) => {
                if (response.status === 404) {
                    throw new Error('Document not found in the system.');
                }
                return response.blob();
            },
        );
    }
}

export default ApiCaller;
