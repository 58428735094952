import { Banner } from './Banner';
import { Footer } from './footer/Footer';
import {useEffect, useState} from "react";
import cert from '../components/blockcerts/testnet-valid-2.0.json';
import Navocerts from "../components/blockcerts/Navocerts";
import {VerifierMain} from "../verifier/main";
import {Background} from "../background/Background";
import {Button} from "../button/Button";
import buttonIcon from '../images/document.svg';
import {useHistory} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {HowItWorks} from "./HowItWorks";

const defaultImage = "/assets/images/square_logo_navozyme.png";

type IVerifierProps = {
    hash: string;
};

const Verifier = (props:IVerifierProps) => {
    const [parsedCert, setParsedCert] = useState<any>();
    const history = useHistory();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    })

    useEffect(() => {
        const navocerts = new Navocerts(cert, props.hash, props.hash);
        navocerts.call().then((parsed) => {
            setParsedCert(parsed);
        });
    }, [props.hash]);

    const navigateHome = () => {
        history.push('/');
    }

    return (
        <Background>
            <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-hidden">
                {parsedCert ?
                    <div className="flex-none">
                        <Banner title={parsedCert.badge?.name} subtitle={parsedCert.badge?.description}
                                button={<Button children={'Verify PDF file'} icon={buttonIcon} onClick={navigateHome} hideMobile={!isDesktopOrLaptop}/>}/>
                        <VerifierMain image={defaultImage} src={parsedCert}/>
                    </div> :
                    <div className="flex-none">
                        <p></p>
                    </div>
                        }
                { parsedCert &&
                    <div className="flex flex-col grow items-center justify-end pb-8 sm:pb-40">
                        <div>
                            <HowItWorks/>
                        </div>
                        <div>
                            <Footer />
                        </div>
                    </div>
                }
            </div>
        </Background>
    );
}

export { Verifier };
