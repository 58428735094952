import {downloadCertificateFile} from "./ApiCommands";
import { getSha256DocumentHash } from "./getDocumentHash";

/**
 * Downloads the credential File with embbeded QR original
 * @param credentialId the Credential ID
 */
export async function getOriginalFile(credentialId: string): Promise<ArrayBuffer> {
    try {
        const originalFile = await downloadCertificateFile(credentialId);
        return await originalFile.arrayBuffer();
    } catch (e) {
        throw e
    }
}

/**
 * Compares a filehash with thw original credential.
 * Download original credential file, calculates hash and compares.
 * @param credentialId Credential ID from QR
 * @param originalHash Original uploaded file hash
 */
export async function compareCredentialWithOriginal(credentialId: string, originalHash: string): Promise<boolean> {
    const originalFile = await getOriginalFile(credentialId);
    const newFileHash = await getSha256DocumentHash(originalFile);

    return newFileHash === originalHash;
}
