/* eslint-disable */

const Settings = {
    getApiURL: (): string => {
        //const baseUrl = process.env.REACT_APP_API_ENDPOINT ?? 'http://localhost';
        const baseUrl = process.env.REACT_APP_API_ENDPOINT ?? 'http://navocerts.staging.n-cap.net/api';
        return `${baseUrl}`;
    },

    getLandingURL: (): string => {
        return 'https://n-cap.net';
    }
}

export default Settings;
